import * as React from "react"
// import Layout from "../components/layout"
import Seo from "../components/seo"
import * as styles from "../styles/404.module.css"

const NotFoundPage = () => (
  // <Layout>
  <section className={styles.container}>
    <Seo title="404: Not found" />
    <article className={styles.article}>
      <h1>404</h1>
      <h2>Not Found</h2>
      <p>페이지가 존재하지 않거나 사용할 수 없는 페이지입니다.</p>
      <p>입력하신 주소가 정확한지 다시 한 번 확인해주세요.</p>
    </article>
  </section>
  // </Layout>
)

export default NotFoundPage
